import styled from "@mui/material/styles/styled";
import Autocomplete from "@mui/material/Autocomplete";

export const OrangeAutoComplete = styled(Autocomplete)(({ theme }) => ({
  color: theme.status.danger,
  fontFamily: "Kanit",
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: theme.status.danger,
    },
  },
  "& .MuiInputBase-input": {
    fontFamily: "Kanit",
  },
  "& .MuiAutocomplete-option": {
    backgroundColor: theme.status.danger,
    color: "whtie",
    fontFamily: "Kanit",
  },
}));
