import createTheme from "@mui/material/styles/createTheme";
import { orange, grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
      dangerHigh: string;
    };
  }

  interface ThemeOptions {
    status?: {
      danger?: string;
      dangerHigh?: string;
    };
  }
}

export const theme = createTheme({
  status: {
    danger: orange[800],
    dangerHigh: orange[900],
  },
  typography: {
    fontFamily: "Kanit",
  },

  palette: {
    text: {
      primary: grey[700],
    },
    primary: {
      main: orange.A700,
      contrastText: "#fff",
    },
  },
});
