import { Suspense, lazy } from "react";

import ThemeProvider from "@mui/material/styles/ThemeProvider";

import { theme } from "./components/mui-theme";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// import OrderHome from "./pages/home";
const OrderHome = lazy(
  () => import(/* webpackChunkName: "order-home" */ "./pages/home")
);

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV === "production",
    },
  },
});

export default function Root(props) {
  return (
    <Suspense fallback="Loading...">
      <section style={{ fontFamily: "Kanit" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <OrderHome singleSpa={props.singleSpa} />
            </QueryClientProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </section>
    </Suspense>
  );
}
